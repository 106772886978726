/*
 * BLOG: model.js
 * update: 2023/11/20
 * --- description ---
*/

import userEnv from "userEnv";

import * as router from "./lib/router";
import * as gevent from "./lib/gevent";
import * as util from "./lib/util";
import * as ajax from "./lib/fetch";

const stateMap = {
  divisor: 6, // 1ページに表示する投稿件数
  channel: {1: "tech", 2: "familly", 3: "memory"},
  listpost: undefined
};

const urlPrefixCms = userEnv.urlPrefixBlog;

// --- Module Declaration --------------
const Cms = {};


// ------------ CMS Section -------------
Cms.get= id => {
  const token = null;
  const url = `${urlPrefixCms}/blog/${id}`;
  const params = null;

  ajax
    .get(url, params, token)
    .then(resp => {
      gevent.publish("getPost", resp);
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

// pagination作成で全投稿件数を取得
Cms.getSum= () => {
  const token = null;
  const url = `${urlPrefixCms}/len`;
  const params = null;

  ajax
    .get(url, params, token)
    .then(resp => {
      resp.divisor = stateMap.divisor;
      gevent.publish("getSum", resp);
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

Cms.list = currentPage => {
  const { user, divisor } = stateMap;
  const url = `${urlPrefixCms}/bloglist/${currentPage}/${divisor}`;
  const params = null;
  const token = null;

  ajax
    .get(url, params, token)
    .then(resp => {
      stateMap.listpost = resp;
      gevent.publish("listPost", resp);
    })
    .catch(error => {
      gevent.publish("error", error);
    });
};

Cms.getChannel = n => stateMap.channel[n];

export { Cms };
