/* BLOG:template.js
 * update: 2023/11/21
 * ---- description -----
 *
 */

import { date2jst } from "./lib/util";
import { Cms } from "./model";

const posts = () => {
  return `
  <section class="ly-posts">
    <h1>投稿リスト</h1>
    <div id="posts-article"></div>
    <footer><ul id="posts-pagination" class="ly-pagination"></ul></footer>
  </section>`;
};

const postsArticle = data => {
  const article = data.map(
    ({id, title, content, draft, channel, created_at, updated_at}) => {
      const saveAt = (date2jst(created_at)).split(" ")[0];
      const updateAt = (date2jst(updated_at)).split(" ")[0];
      let summary = "";
      if (content) {
        summary = content.split('\n').slice(1).join('\n');
      }
      
      return `
      <article class="el-posts-post">
        <h1><a href="/post/${id}">${title}</a></h1>
        <aside>
          <ul>
            <li>更新:&ensp;${updateAt}</li>
            <li>${Cms.getChannel(channel)}</li>
          </ul>
        </aside>
        <p>${summary}</p>
      </article>
      `;
    }
  ).join("");

  return article;
};

const post = () => {

  return `
  <article class="ly-editor">
    <div id="post-content" class="el-content"></div>
    <footer class="tooltip">
      <a href="/posts">
        <span class="material-symbols-outlined arrow_back">arrow_back</span>
      </a>
      <span class="tooltiptext">投稿リストに戻る</span>
    </footer>
  </article>`;
};

const warning = err => {
  return `
  <article class="error-wrapper">
    <h1>${err.title}</h1>
    <p>${err.message}</p>
  </article>`;
};

export {
  posts,
  postsArticle,
  post,
  warning
};
