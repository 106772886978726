/* base: paginate
 * update: 2023/11/12
 * ---description---
 *１行に表示する列数は7列
 * 行の開始は１、行の終わりはページ数：ページ数が６ならば６，８ならば８を表示
 * ページ数が６ならば全数字を表示
 * ページ数が８ならば 以下ケース分けする
 * case:現在表示しているページが２ならば、12345...8
 * case:現在表示しているページが３ならば、1...345...8
 */


const stateMap = {
  pageMax: 0,
  range: 7, // 1行に表示するページの列数
  currentPage: 1,
};

// ----- utility ------------
// 指定された数字に対して繰り上げの操作を行い
// 割り算の結果の端数を含めて最も近い大きな整数に丸めます
const roundUp = (num, divisor) => {
    if(typeof num !== 'number' || typeof divisor !== 'number') {
        return 'Input needs to be a number';
    }
    stateMap.pageMax = Math.ceil(num/divisor);
};

// ---- BEGIN DOM METHODS -----------
const createHtml = displayPages => {
  const { pageMax, range, currentPage } = stateMap;

  const pagination = displayPages.map((ele, index) => {
    const active = currentPage === Number(ele) ? "active" : "no";
    let val = ele;
    if ( ele === "..." ) {
      if (index == 1 ) {
        val = currentPage - 1;
      } else {
        val = currentPage + 1;
      }
    }
    return `
      <li class="${active}" data-page="${val}">${ele}</li>
    `;
  });
  return pagination;
};

const createPagination = () => {
  const { pageMax, range, currentPage } = stateMap;
  const displayPages = [];

  if (pageMax <= range) {
    for (let i = 1; i <= pageMax; i++) {
      displayPages.push(i);
    }
  } else {
    if (currentPage <= 2) {
      for (let i = 1; i <= 5; i++) {
        displayPages.push(i);
      }
      displayPages.push('...');
      displayPages.push(pageMax);
    } else if (currentPage >= pageMax - 1) {
      displayPages.push(1);
      displayPages.push('...');
      for (let i = pageMax - 3; i <= pageMax; i++) {
        displayPages.push(i);
      }
    } else {
      displayPages.push(1);
      displayPages.push('...');
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        displayPages.push(i);
      }
      displayPages.push('...');
      displayPages.push(pageMax);
    }

  }
  return createHtml(displayPages);
};

const paginate = currentPage => {
  const { pageMax } = stateMap;
  stateMap.currentPage = currentPage;

  const pagination = createPagination();

  // 列の最初に追加
  if (currentPage > 1) {
    pagination.splice(0,0, `
      <li class="tooltip">
        <span class="material-symbols-outlined" data-page="${currentPage - 1}">chevron_left</span></i>
        <span class="tooltiptext">Previous</spn>
      </li>`);
  }

  // 列の最後に追加
  if (currentPage < pageMax) {
    pagination.push(`
      <li class="tooltip">
        <span class="material-symbols-outlined" data-page="${currentPage + 1}">chevron_right</span>
        <span class="tooltiptext">Next</span>
      </li>`);
  }

  return pagination.join("");
};
        
const init = (posts) => {
  roundUp(posts.length, posts.divisor);
};

export { init, paginate };
