/*
 * lib router.js
 * Update 2023/11/08 
 * ------ description --------
*/

const stateMap= {
  schemaList: null,
  pathPrefix: null
};


const historyMap = [];

//schemaにないページはdefault_pageに誘導
const setConfig = (anchor_schema, pathPrefix, index="index") => {
  stateMap.schemaList = anchor_schema;
  stateMap.pathPrefix = pathPrefix;
  stateMap.index = index;
  //console.info("stateMap", stateMap);
};

//想定のケースは以下
// domain/ --> anchor=''
// domain/<anchor> --> anchor in schemaList
// domain/blog/<entry>/<ID> --> anchor=blog
const getCleanAnchorString = () => {
  const { pathPrefix, index } = stateMap;
  const pathname = document.location.pathname.replace(pathPrefix, "");
  //console.info("pathname", pathname);
  if (pathname === "/" || pathname == `/${index}`) {
    return {page: null};
  }
  const urlPathList = pathname.split('/').slice(1);
  const isSchema = stateMap.schemaList.includes(urlPathList[0]);
  if (isSchema) {
    return {page: urlPathList};
  } else {
    const err = {
      name: "schema",
      notification: "Page not found!",
      data: null
    };
    throw err;
  }
};

/* アドレスバーにuriをセットしてpopstateイベントを発行する
 * pushState: 新しい履歴エントリを作成する
 * replaceState: 現在の履歴エントリを書き換える
 * 第一引数のstate: 任意のオブジェクトを格納でき、shellのonPopstateで取り出せる
 */
const setAnchor = (anchorMap, replace_state=false) => {
  const { pathPrefix } = stateMap;
  const anchor = anchorMap.page;
  const uri_string = Array.isArray(anchor) ? '/' + anchor.join('/') : "/" + anchor;
  const state = history.state;

  const urlPath = pathPrefix + uri_string;
  if ( replace_state ) {
    // 現在のアドレスは新しいアドレスで置き換えられるので
    // 履歴から消える
    history.replaceState(null, null, urlPath);
  } else {
    //２番目の引数は無視される--mdn pushState()
    history.pushState(state, null, urlPath);
  }
  window.dispatchEvent(new Event('popstate'));
};

// 引数はdefault_anchor
// アドレスバーのuriをmapに格納する
const makeAnchorMap = anchor =>{
  const anchorMap = getCleanAnchorString();
  //console.info('anchorMap', anchorMap);
  if (anchorMap.page === null) {
    anchorMap.page = Array.isArray(anchor) ? anchor : [anchor];
    setAnchor(anchorMap);
    return false;
  } else {
    return anchorMap;
  }
};

//page=[schema,,,]
//現在のurl履歴を登録する
//戻るリンクの不適切な循環を防止する
//ひとつ前の画面==historyMap[idx-1]
//historyMap=履歴の格納
//ひとつ前の画面のページ配列をunderbarで連結する 
// page = Array
const testHistory = page => {
  const pageHistory = page.join('_');
  let idx = historyMap.indexOf(pageHistory);

  if (stateMap.schemaList.indexOf(pageHistory) > -1) {
    //schemaListに一致するものがないかチェックする
    //連結するものがなかった([latest]等)場合(>-1)になる
    historyMap.length = 0;
    historyMap.push(pageHistory);
    //この場合は前のページはないのでnullを返すためにidx=0
    idx = 0;
  }
  else if (idx === -1) {
    //pageHistoryに未格納な新しいページがあるので格納する
    historyMap.push(pageHistory);
    idx = historyMap.length - 1;
  }
  else if ((historyMap.length - idx) > 1) {
    //同じものがある場合は循環を避けるために後順位を捨てる
    //例:[ab, abc, abcd]の時にabcが来たら[ab, abc]としてabcdを捨てる
    const historyMapNext = historyMap.slice(0, idx + 1);
    historyMap.length = 0;
    Object.assign(historyMap, historyMapNext);

  }
  //historyMap.length - idx == 1 -->重複クリック
  console.info('historyMap, idx', historyMap, idx);
  return idx === 0 ? null : historyMap[idx-1].split('_');
};

const setBreadCrumb = () => {
  const len = historyMap.length;
  // display no bread crumb as len === 1
  const breadcrumb = len === 1 ? '' : historyMap.map((url, idx) => { 
    const page = url.split('_')[0];
    const href = url.replace(/_/g, '/');
    return idx === (len - 1) ? `<li>${page}</li>`
      : `<li><a href="/${href}">${page}</a></li>`;}).join(' >> ');
  document.querySelector('.breadcrumb').innerHTML = breadcrumb;
};

export {
  setConfig,
  setAnchor,
  historyMap,
  makeAnchorMap,
  testHistory,
  setBreadCrumb
};
