/*
 * lib gevent.js
 * ------ description --------
 * Update 2023-09-22 
*/

const customEvent = {};
const eventTarget = {};

const publish = (eventName, data) => {
  const event = new CustomEvent(eventName, {detail: data});
  eventTarget.spa.dispatchEvent(event);
};

// eventNameは競合不可
// moduleIdはanchor_schemaに一致
const subscribe = (moduleId, eventName, fn) => {
  eventTarget.spa.addEventListener(eventName, fn);
  if (!(moduleId in customEvent)) {
    customEvent[moduleId] = [];
  }
  customEvent[moduleId].push({
    eventName: eventName,
    func: fn
  });
};

const unsubscribe = moduleId => {
  if (customEvent.hasOwnProperty(moduleId)) {
    (customEvent[moduleId]).forEach(obj => (
      eventTarget.spa.removeEventListener(
        obj.eventName, obj.func
      )
    ));
    delete customEvent[moduleId];
  }
};

const gint = spa => {
  eventTarget.spa = document.getElementById(spa);
};

// -- public methods ----
export {
  gint,
  publish,
  subscribe,
  unsubscribe
};
