/*
 * lib util.js
 * ------ description --------
  export {
    date2jst,
    date2ut,
    formatError,
    deepCopy,
    sortBy,
    sortByTimeDesc,
    ltrim,
    encodeHtml,
    extractDateList
  };
 * Update 2023-09-21 
*/


const statusCode = {
  400: "400: Bad Requesst",
  401: "401: Unauthorized",
  403: "403: Forbidden",
  404: "404: Page Not Found",
  500: "500: Internal Server Error",
  999: "Network Handling Error"
};

// 表示用にエラーをフォーマットする
// response code = {status: xhr.status, response: xhr.response}
// custom error = {name:, message:}
const formatError = err => {
  let code, message;
  if ('name' in err) {
    code = parseInt(err.name, 10);
    message = err.message;
  } else {
    code = err.status;
    message = err.response;
  }

  return {
    title: statusCode[code],
    message: message
  };
};

const deepCopy = origin => JSON.parse(JSON.stringfy(origin));

const sortBy = (arr, k) => (
  arr.concat().sort((a, b) => (a[k] > b[k]) ? 1 : ((a[k] < b[k]) ? -1 : 0))
);

const sortByTimeDesc = items => {
  return items.concat().sort((a, b) => {
    const pre = new Date(a.save_at);
    const aft = new Date(b.save_at);
    return pre < aft ? 1 : (pre > aft) ? -1 : 0;
  });
};

// delete space
const ltrim = line => {
  return line.replace(/[\s\u3000]+/g, '');
};


/* encodeHTML
 * タグに埋め込む文字列をHTMLの実体参照にエスケープする
 * 通常は次の５文字
 * <>&"'->&lt;&gt;&amp;&quot;&apos
 */
const encodeHTML = val => {
  const dom = document.createElement('div');
  dom.appendChild(document.createTextNode(val));
  //console.info(dom.innerHTML);
  return dom.innerHTML;
};

/****** DateTime Utilities ******************/
// Extract [year, month, day, hour, minute, second, millisecond] from date
// 日付から上記リストを返す
const extractDateList = (date, h=9) => {
  const copy = new Date();
  copy.setTime(date.getTime());
  copy.setHours(copy.getHours() + h );
  return copy
    .toISOString()
    .split(/[^0-9]/)
    .slice(0, -1);
};

// jst to unix time
const date2ut = (date, h=9) => {
  const copy = new Date();
  copy.setTime(date.getTime());
  copy.setHours(copy.getHours() - h );
  return copy
};

// unix to jst time
const date2jst = (ut, h=9) => {
  const copy = new Date(ut);
  copy.setHours(copy.getHours() + h );
  return copy.toLocaleString();
};

// ---- Public Methods -----
export {
  date2jst,
  date2ut,
  formatError,
  deepCopy,
  sortBy,
  sortByTimeDesc,
  ltrim,
  encodeHTML,
  extractDateList
};
