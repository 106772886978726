/* BLOG: posts
 * update: 2023/11/15
 * ---description---
 *
 */

import * as gevent from "./lib/gevent";
import * as paginate from "./lib/paginate";
import { Cms } from "./model";
import * as template from "./template";

// ---- BEGIN MODILE SCOPE VARIABLES -----
const stateMap = {
  container: undefined,
  currentPage: 1,
};

const domMap = {};

// shellの公開オブジェクトを参照する場合はここで宣言
const configMap = {};
// ---- END MODULE SCOPE VARIABLES -----------

// ---- BEGIN UTILITY METHODS -----------

// ---- END UTILITY METHODS -----------
// ---- BEGIN DOM METHODS -----------
const setDomMap = () => {
  domMap.article = document.getElementById("posts-article");
  domMap.pagination = document.getElementById("posts-pagination");
};

const render = data => {
  domMap.article.innerHTML = template.postsArticle(data);
  const html = paginate.paginate(stateMap.currentPage);
  domMap.pagination.innerHTML = html;
};

// ---- END DOM METHODS -----------
// ---- BEGIN EVENT METHODS -----------
const onInitPagination = event => {
  const { currentPage } = stateMap;
  const posts = event.detail;
  paginate.init(posts); 
};

const onPaginate = ev => {
  const page = ev.target.dataset.page;
  stateMap.currentPage = Number(page);
  Cms.list(page);
};

const onArticle = ev => {
  const element = ev.target;
  console.info("onArticle", element.name, element.tagName);
  
  if (element.tagName.toLowerCase() === 'input' && element.name === 'draft') {
    const postid = element.dataset.id;
    Cms.draft(Number(postid));
  }
};

const onPosts = event => {
  const posts = event.detail;
  render(posts);
};

// ---- END EVENT METHODS -----------

// ---------- BEGIN PUBLIC METHODS ------------
// shellがルーティングで使用する
const config = inputMap => {
  Object.keys(inputMap).forEach(keyName => {
    if (configMap.hasOwnProperty(keyName)) {
      configMap[keyName] = inputMap[keyName];
    }
  });
};

const init = container => {
  stateMap.container = container;
  stateMap.container.innerHTML = template.posts();
  setDomMap();

  // グローバルカスタムイベントのバインド
  gevent.subscribe("posts", "getSum", onInitPagination);
  gevent.subscribe("posts", "listPost", onPosts);

  domMap.article.addEventListener('click', onArticle, false); 
  domMap.pagination.addEventListener('click', onPaginate, false); 
  //
  // initial data
  Cms.getSum();
  Cms.list(stateMap.currentPage);
};


// ---------- END PUBLIC METHODS ------------

export { config, init };
