/* BLOG: post 
 * update: 2023/11/14
 * ---description---
 */

import * as gevent from "./lib/gevent";
import { Cms } from "./model";
import { post } from "./template";

// ---- BEGIN MODILE SCOPE VARIABLES -----
const stateMap = {
  container: undefined,
  postid: null,
};

const domMap = {};

// shellの公開オブジェクトを参照する場合はここで宣言
const configMap = {
  previous: null,
  url: null
};
// ---- END MODULE SCOPE VARIABLES -----------

// ---- BEGIN UTILITY METHODS -----------

// ---- END UTILITY METHODS -----------
// ---- BEGIN DOM METHODS -----------
const setDomMap = () => {
  domMap.content = document.getElementById("post-content");
};

const render = data => {
  domMap.content.innerHTML = data;
};

// ---- END DOM METHODS -----------
// ---- BEGIN EVENT METHODS -----------

const onGetPost = event => {
  const data = event.detail;
  render(data.content);
};

// ---- END EVENT METHODS -----------

// ---------- BEGIN PUBLIC METHODS ------------
// shellがルーティングで使用する
const config = inputMap => {
  Object.keys(inputMap).forEach(keyName => {
    if (configMap.hasOwnProperty(keyName)) {
      configMap[keyName] = inputMap[keyName];
    }
  });
};

const init = container => {
  stateMap.container = container;
  stateMap.container.innerHTML = post();
  setDomMap();

  const path = configMap.url;
  if (path.length === 2) {
    stateMap.postid = decodeURI(configMap.url[1]);

    // グローバルカスタムイベントのバインド
    gevent.subscribe("post", "getPost", onGetPost);

    // initial data
    Cms.get(stateMap.postid);
  }
};


// ---------- END PUBLIC METHODS ------------

export { config, init };
