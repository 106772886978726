/*
 * lib fetch.js
 * ------ description -------------------
 * Update 2023/10/10 
*/

// ------- inside utility ============
const newHeader = value => {
  const { type, token } = value;
  const headers = new Headers();
  if (type === "form" ) {
    headers.set('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
  } else if (type === "json") {
    headers.set('Content-Type', 'application/json; charset=UTF-8');
  }

  console.info("header", value, type);
  if (token) {
    headers.set('Authorization', `Bearer ${token}`);
  }
  
  return headers;
};

// paramsが無い場合はnull
const encodedString = params => {
  return Object.keys(params).map(key => {
    let val = params[key];
    if (typeof val === 'object') val = JSON.stringify(val);
    return [key, encodeURIComponent(val)].join('=');
  }).join('&');
};

// --------- end utility ============
// wrap the fetch
function fetchRequest(url, opts) {
  console.info("opts", opts);
  return fetch(url, opts)
    .then(resp => {
      console.info("fetch resp", resp);
      if (!resp.ok) {
        return Promise.reject({status: resp.status, response: resp.json()});
      } else {
        return resp.json();
      }
    });
}

const get = (url, params=null, token=null) => {
  const headers = newHeader({type: null, token: token});
  const urlString = params ? encodedString(params) : null;
  if (urlString) {
    url += '?' + urlString;
  }
  console.info("headers", headers);
  return fetchRequest(url, {
    method: 'GET',
    headers: headers,
  });
};

const remove = (url, params=null, token=null) => {
  const headers = newHeader({type: null, token: token});

  return fetchRequest(url, {
    method: 'DELETE',
    headers: headers,
    params: params ? encodedString(params) : null,
  });
};

const form = (url, params=null, token=null) => {
  const headers = newHeader({type: "form", token: token});

  return fetchRequest(url, {
    method: 'POST',
    headers: headers,
    body: params ? encodedString(params) : '',
  });
};

const post = (url, method="POST", params=null, token=null) => {
  const headers = newHeader({type: "json", token: token});

  return fetchRequest(url, {
    method: method,
    headers: headers,
    body: params ? JSON.stringify(params) : '',
  });
};

// ---- handler load image -----------------
// load image with token
const imgLoad = (url, authToken) => {
  const headers = newHeader({type: null, token: authToken});
  return fetch(url, { headers });
};

const imgUpload = (url, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append('file', file);

    return fetch(url, {
      method: 'POST',
      body: formData
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Image uploading failed with error code:' + response.statusText);
      }
    })
    .catch(error => {
      console.error('Error:', error);
      throw error;
    });
  });
};

// -------- Public Methods ------------------
export {
  get,
  remove,
  post,
  form,
  imgLoad,
  imgUpload
};
